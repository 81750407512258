import container from '../../libs/container/container';
import preloader from "../../libs/preloader/preloader";
import $ from "jquery";

const amdLayoutModule = (function () {
    const name = 'layout';

    const view = container.getBaseView(name);

    view.privateVars = {

    }

    view.service = {

    }

    view.startup = function () {
        preloader.setPreloadBulk(name, view.publicVars.preloadTab);
    };

    view.bind = function () {
        document.querySelector(view.publicVars.cssId.headerLangSwitcherCls).addEventListener('click', function () {
            view.methods.actionToggleLangSwitchMenu();
        });

        document.querySelector(view.publicVars.cssId.headerMenuIconCls).addEventListener('click', function () {
            view.methods.actionToggleMenu();
        });

        document.documentElement.addEventListener('click', function (e) {
            const container = document.querySelector(view.publicVars.cssId.headerLangSwitcherCls);

            if (container.classList.contains(view.publicVars.cssId.headerLangSwitcherIsOpenCls.substring(1))
                && (!e.target.closest(view.publicVars.cssId.headerLangSwitcherCls)))
            {
                view.methods.actionToggleLangSwitchMenu();
            }
        });
    };

    view.methods = {
        actionToggleLangSwitchMenu: () => {
            const cssHeaderLangSwitcher = document.querySelector(view.publicVars.cssId.headerLangSwitcherCls);

            cssHeaderLangSwitcher.classList.toggle(view.publicVars.cssId.headerLangSwitcherIsOpenCls.substring(1));
        },

        actionToggleMenu: () => {
            const cssHeader = document.querySelector(view.publicVars.cssId.headerCls);

            cssHeader.classList.toggle(view.publicVars.cssId.headerIsMenuOpenCls.substring(1));
        }
    }

    return {
        init: function (options) {
            view.init(options);
        }
    }
})();

amdLayoutModule.init(window.extLayoutModuleConfig);