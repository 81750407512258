const container = (function () {

    return {
        /*
         * Perform copy of properties from object to object
         * It's a deep copy so complex objects are also copied.
         * @param Object outputObject object to copy properties
         * @param Object copyObject object from which properties are copied
         */
        performCopy: function (outputObject, copyObject) {
            outputObject = outputObject || {};
            copyObject = copyObject || {};

            for (var i in copyObject) {
                if (typeof copyObject[i] === 'object') {
                    if (!outputObject[i]) {
                        outputObject[i] = (copyObject[i].constructor === Array) ? [] : {};
                    }
                    this.performCopy(outputObject[i], copyObject[i]);
                } else {
                    outputObject[i] = copyObject[i];
                }
            }

            return outputObject;
        },
        /*
         * Show message in console
         * @param String msg - message to show
         */
        msg: function (name, msg) {
            if (typeof console === 'object' && typeof console.info === 'function') {
                console.info(name + ' - ' + msg);
            }
        },
        /*
         * Get data for given element handle and field name based on datasets
         * @param Resource element - element which has attribute
         * @param String field - attribute name
         *
         * @call
         * <div id="some-id" data-some.data="11"></div>
         * container.getData($('#some-id')[0], 'some.data');
         */
        getData: function (element, field) {

            var value = '';

            if (element.dataset !== undefined) { // standard approach
                value = element.dataset[field];
            } else {
                value = element.getAttribute('data-' + field); // IE approach
            }

            return value;
        },

        /*
         * Set data for given element handle and field name based on datasets
         * @param Resource element - element which has attribute
         * @param String field - attribute name
         * @param String value
         *
         * @call
         * <div id="some-id" data-some.data="11"></div>
         * container.getData($('#some-id')[0], 'some.data', 'some value');
         */
        setData: function (element, field, value) {

            if (element.dataset !== undefined) { // standard approach
                element.dataset[field] = value;
            } else {
                element.setAttribute('data-' + field, value); // IE approach
            }
        },

        /*
         * Get base view for modules
         */
        getBaseView: function (name) {

            let systemName = name || 'system';

            return {
                privateVars: {},
                publicVars: {},
                init: function (options) {
                    this.publicVars = options || {};
                    this.prepareView();

                    container.msg(systemName,'view init done');
                },
                prepareView: function () {
                    this.startup();
                    this.bind();
                },
                startup: function () {

                },
                bind: function () {

                }
            }
        },

        /*
         * Get Observer Pattern
         */
        getObserver: function () {
            return observerPattern;
        },
        /*
         * Get Publish Subscribe Pattern
         */
        getPublishSubscribe: function () {
            return publishSubscribePattern;
        },

    };
})();

export default container;