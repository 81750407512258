import container from "../container/container";
import $ from "jquery";

const preloader = (function () {

    let publicVars = {};

    var privateVars = {
        name: 'preloader',
        imgTab: {}, /* img id tab */
        imgTabKeys: {}, /* holds collection id for next key */
        imgBulkMax: {}, /* count all images for collections */
        imgBulkCount: {},
        imgCached: {} /* holds path of images for given collection */
    };


    var privateMethods = {
        prepareObj: function (options) {
            publicVars = options || {};

            privateMethods.bind();
        },
        bind: function () {
            container.msg(privateVars.name,'module started');
        },
        /*
         * preload image helper
         *
         * @param String collection (name of image collection)
         * @param String path (path of image)
         * @param Int key (key for image to load)
         * @param Int status (0 or 1 (0 new, 1 already preloaded))
         * @param Function callback (function to call after preaload image)
         */
        handlePreloadedImg: function (collection, path, key, status, callback) {

            if (status == 1) {
                container.msg(privateVars.name, ' img in memory: ' + collection + ' - ' + key + ' - ' + path);
            } else {
                container.msg(privateVars.name, ' img preloaded: ' + collection + ' - ' + key + ' - ' + path);

                privateVars.imgTab[collection][key] = 1;
                privateVars.imgCached[collection][key] = path;

            }

            if (typeof callback === 'function') {
                callback();
            }

        },
        /*
         * image preloader
         *
         * @param String collection (name of image collection)
         * @param String path (path for image)
         * @param Functon callback (function to call after image preload)
         *
         * @return Int key for preloaded image
         */
        preloadSingleImage: function (collection, path, callback) {
            if (typeof privateVars.imgTab === 'object') {

                privateVars.imgTab[collection] = privateVars.imgTab[collection] || {};
                privateVars.imgTabKeys[collection] = privateVars.imgTabKeys[collection] || 0;
                privateVars.imgCached[collection] = privateVars.imgCached[collection] || [];

                var key = $.inArray(path, privateVars.imgCached[collection]);

                if (key != -1) {
                    privateMethods.handlePreloadedImg(collection, path, key, 1, callback);
                } else if (path === '') {
                    privateMethods.handlePreloadedImg(collection, path, key, 1, callback);
                } else {

                    privateVars.imgTabKeys[collection]++;
                    key = privateVars.imgTabKeys[collection];

                    var image = new Image();
                    image.onload = function () {
                        privateMethods.handlePreloadedImg(collection, path, key, 0, callback);
                    };

                    image.onerror = function () {
                        privateMethods.handlePreloadedImg(collection, path, key, 0, callback);
                    };

                    image.src = path;
                }

                return key;
            }
        },
        /*
         * check if image is preloaded
         *
         * @param String collection (name of image collection)
         * @param Int key (key for image to check)
         *
         * @return Boolean true or false
         */
        isPreloaded: function (collection, key) {
            if (key in privateVars.imgTab[collection]) {
                return true;
            } else {
                return false;
            }
        },
        /*
         * check if image is preloaded for given path
         *
         * @param String collection (name of image collection)
         * @param String path (path for image to check)
         *
         * @return Boolean true or false
         */
        isPreloadedPath: function (collection, path) {
            if ($.inArray(path, privateVars.imgCached[collection]) != -1) {
                return true;
            } else {
                return false;
            }
        },
        /*
         * set multiple images for preload
         *
         * @param String collection (name of image collection - bulk slug is automatically added)
         * @param Array imgTab (tab of images for preload)
         *
         * @return Boolean true or false
         */
        setPreloadBulk: function (collection, imgTab) {

            collection = 'bulk-' + collection;

            if (privateVars.imgBulkMax[collection] && privateVars.imgBulkMax[collection] > 0) {
                container.msg(privateVars.name, 'bulk collection already set: ' + collection);
            } else {

                privateVars.imgBulkMax[collection] = 0;
                privateVars.imgBulkCount[collection] = 0;
                privateVars.imgTab[collection] = {};
                privateVars.imgTabKeys[collection] = 0;
                privateVars.imgCached[collection] = [];

                var count = 0;

                for (var key in imgTab) {

                    privateMethods.preloadSingleImage(collection, imgTab[key], function () {
                        privateVars.imgBulkCount[collection]++;
                    });

                    count++;
                }

                privateVars.imgBulkMax[collection] = count;

                container.msg(privateVars.name, 'bulk count set: ' + privateVars.imgBulkMax[collection]);

            }
        },
        /*
         * get current bulk preload info
         *
         * @param String collection (name of image collection - bulk slug is automatically added)
         *
         * @return Object (with count, max, percent and status)
         */
        getPreloadBulk: function (collection) {

            var done = 0;

            collection = 'bulk-' + collection;

            if (privateVars.imgBulkMax[collection] !== undefined) {

                if (privateVars.imgBulkCount[collection] == privateVars.imgBulkMax[collection]) {
                    done = 1;
                }

                var percent = 0;

                if (privateVars.imgBulkMax[collection] > 0) {
                    percent = Math.ceil(privateVars.imgBulkCount[collection] / privateVars.imgBulkMax[collection] * 100);
                } else {
                    percent = 100;
                }

            } else {
                done = 1;

                container.msg(privateVars.name, 'bulk wrong name given');
            }

            return {
                preloadedCount: privateVars.imgBulkCount[collection],
                preloadedMax: privateVars.imgBulkMax[collection],
                preloadedPercent: percent,
                status: done,
                collection: collection
            };


        },
        /*
         * sets interval for bulk loading and fires callback when preload is done
         *
         * @param String collection (name of image collection)
         *
         */
        getPreloadBulkFinished: function (collection, callback) {


            var inter = setInterval(function () {

                var info = privateMethods.getPreloadBulk(collection);

                console.dir(info);

                if (info.status == 1) {
                    clearInterval(inter);

                    container.msg(privateVars.name, 'bulk preload done: ' + collection);

                    if (typeof callback === 'function') {
                        callback();
                    }
                }

            }, 10);
        }

    };


    var publicObj = {
        init: function (options) {
            privateMethods.prepareObj(options);
        },
        preloadSingleImage: function (collection, path, callback) {
            return privateMethods.preloadSingleImage(collection, path, callback);
        },
        getImgTab: function () {
            return privateVars.imgTab;
        },
        isPreloaded: function (collection, key) {
            return privateMethods.isPreloaded(collection, key);
        },
        isPreloadedPath: function (collection, path) {
            return privateMethods.isPreloadedPath(collection, path);
        },
        setPreloadBulk: function (collection, imgTab, callback) {
            privateMethods.setPreloadBulk(collection, imgTab);
        },
        getPreloadBulk: function (collection) {
            return privateMethods.getPreloadBulk(collection);
        },
        getPreloadBulkFinished: function (collection, callback) {
            privateMethods.getPreloadBulkFinished(collection, callback);
        }
    }


    return publicObj;
})();

export default preloader;


/*
 
 -----
 preloader.preloadSingleImage('system', view.publicVars.baseurl + '/assets/img/layout/character4.png', function() {
 
 });
 -----
 preloader.setPreloadBulk('system', [view.publicVars.baseurl + '/assets/img/layout/character4.png', 
 view.publicVars.baseurl + '/assets/img/layout/character5.png']);
 -----
 preloader.getPreloadBulkFinished('system', function() {
 console.log('done');
 });
 -----
 var inter = setInterval(function() {
 
 var info = preloader.getPreloadBulk('system');
 
 console.dir(info);
 
 if(info.status == 1) {
 clearInterval(inter);
 }
 
 }, 10);
 -----
 */